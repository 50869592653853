import React from 'react'
import { useHistory } from 'react-router-dom'
import BackArrow from '../../images/icons/BackArrowSvgIcon.svg'
import styles from './PageBackTitle.module.scss'

const PageBackTitle = ({ title = '', className = '' }) => {
  const history = useHistory()

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <BackArrow className={styles.backArrow} onClick={handleBack} />
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default PageBackTitle
