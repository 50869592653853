import React from 'react'
import styles from './AuthForm.module.scss'
import FormItem from '../FormItem'
import Input from '../Input'
import Button from '../Button'
import { getFormattedDate } from '../../utils/dateFotmater'
import Form from '../Form'
import { Link } from 'react-router-dom'
import { PWD_RESET_REQUEST_PAGE } from '../../constants'

const LoginForm = ({ user, error, form, onFinish, onSubmit, handleShowRegistrationForm }) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.h1}>
          {user ? `${user?.firstname} ${user?.lastname}` : 'Вход в сервис'}
        </div>
        <div className={styles.h2}>
          {user
            ? `С нами вместе с ${getFormattedDate(user?.dateAdded)}`
            : 'Введите необходимые данные'}
        </div>
      </div>
      <Form form={form}>
        <FormItem name="email" label="E-mail" labelCN={styles.formItem}>
          <Input iconType="check" onKeyDown={onKeyPress} type="email" />
        </FormItem>
        <FormItem name="password" label="Пароль" labelCN={styles.formItem}>
          <Input iconType="eye" onKeyDown={onKeyPress} type="password" />
        </FormItem>
        <div className={styles.submit}>
          <Button onClick={onSubmit}>вход</Button>
          <div className={styles.regBtn} onClick={handleShowRegistrationForm}>
            Регистрация
          </div>
          <div  className={styles.link}>
            <Link to={PWD_RESET_REQUEST_PAGE} onClick={onFinish}>Восстановить Пароль</Link>
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </Form>
    </>
  )
}

export default React.memo(LoginForm)
