import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './Button.module.scss'
import { useHover } from '../../hooks/useHover'
import { MESSENGER_PAGE, MY_ORDERS_PAGE } from '../../constants'
import { BUY } from '../../pages/MessengerPage'

const Button = ({ children, forwardedRef, className, ...props }) => {
  return (
    <button ref={forwardedRef} className={`${styles.btn}${className ? ` ${className}` : ''}`} {...props}>
      {children}
    </button>
  )
}

/**
 * @param icon {'cart'|'message'|'heart'}
 * @param children {jsx}
 * @param props {any}
 * @return {Button}
 */
export const ButtonWithIcon = ({ icon, children, ...props }) => {
  const [ref, isHovered] = useHover()

  return (
    <Button {...props} forwardedRef={ref}>
      <span
        className={`${styles.icon} ${
          isHovered ? styles[`${icon}IconHover`] : styles[`${icon}Icon`]
        }`}
      />{' '}
      {children}
    </Button>
  )
}

export const OrderButton = ({ ...props }) => {
  const history = useHistory()

  const goToOrdersPage = () => {
    history.push(MY_ORDERS_PAGE)
  }

  return (
    <ButtonWithIcon icon="cart" onClick={goToOrdersPage} {...props}>
      Заказать
    </ButtonWithIcon>
  )
}

export const MessageButton = ({ productUuid, ...props }) => {
  const history = useHistory()

  const goToMessengerPage = useCallback(() => {
    return history.push(`${MESSENGER_PAGE}?productUuid=${productUuid}&tab=${BUY}`)
  }, [history, productUuid])

  return (
    <ButtonWithIcon icon="message" onClick={goToMessengerPage} {...props}>
      Написать Продавцу
    </ButtonWithIcon>
  )
}

export const HeartButton = ({ active, ...props }) => {
  // TODO: here logic with add/remove product to wishList
  return (
    <ButtonWithIcon icon={`${active ? 'heartActive' : 'heart'}`} {...props}>
      {active ? 'из избранного' : 'в избранное'}
    </ButtonWithIcon>
  )
}

export default Button
