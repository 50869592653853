import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Notification.module.scss'
import menuStyles from '../Menu/Menu.module.scss'
import Counter from '../Counter'

const Notification = ({ iconClass, count, title, href }) => {
  return (
    <NavLink to={href} className={styles.menuLink} activeClassName={menuStyles.selectedMenu}>
      <span className={`${styles.icon} ${styles[iconClass]}`}>
        <span className={styles.counterBlock}>
          <Counter count={count} />
        </span>
      </span>
      <span>{title}</span>
    </NavLink>
  )
}

export default Notification
