import joi from 'joi'

const selectReq = joi
  .array()
  .required()
  .items({
    id: joi.number().integer().required(),
  })
  .min(1)

const str = joi.string()

const number = joi.number()

export const formSchema = joi.object({
  isbn: str.allow(null, ''),
  title: str.min(3).required(),
  category: selectReq,
  description: joi.string().allow(null, ''),
  productCondition: selectReq,
  delivery: selectReq,
  price: number.min(0).required(),
  // quantity: 1,
  // photos: [],
  manufacturer: joi.array(), // not req
  // sku: null,
  // mpn: null,
  // ean: null,
  // model: null,
  author: str,
  length: str.regex(/^[0-9]+$/).allow(null, ''),
  width: str.regex(/^[0-9]+$/).allow(null, ''),
  height: str.regex(/^[0-9]+$/).allow(null, ''),
  weight: str.regex(/^[0-9]+$/).allow(null, ''),
})
