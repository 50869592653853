import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './routes'
import { ApiContainer } from './containers/ApiContainer'
import { NotificationContainer } from 'react-notifications'

const App = () => (
  <ApiContainer>
    <Router>
      <Routes />
    </Router>
    <NotificationContainer />
  </ApiContainer>
)

export default App
