import React from 'react'
import styles from './Menu.module.scss'
import { NavLink } from 'react-router-dom'
import {
  MY_ADS_PAGE,
  MY_ORDERS_PAGE,
  NEW_ADVERT_PAGE,
  NEW_ADVERTS_MODERATION_PAGE,
  RULES_PAGE
} from "../../constants";
import CartIcon from '../../images/icons/cartSvgIcon.svg'
import AdsIcon from '../../images/icons/adsSvgIcon.svg'
import NewAdsIcon from '../../images/icons/newAdsSvgIcon.svg'

const menuList = [
  { id: 1, link: RULES_PAGE, title: 'Правила' },
  { id: 2, link: MY_ADS_PAGE, title: 'Мои объявления' },
  { id: 3, link: MY_ORDERS_PAGE, title: 'Мои заказы' },
]

export const MenuItem = ({ children, ...props }) => {
  return (
    <div className={styles.menuLink} {...props}>{children}</div>
  )
}

export const RulePageNavLink = ({ className = '' }) => {
  return (
    <NavLink to={RULES_PAGE} className={`${styles.menuLink} ${className}`} activeClassName={styles.selectedMenu}>
      Правила
    </NavLink>
  )
}

export const MyAdsPageNavLink = ({ withIcon }) => {
  return (
    <NavLink to={MY_ADS_PAGE} className={styles.menuLink} activeClassName={styles.selectedMenu}>
      {withIcon && <AdsIcon className={styles.icon}/>} Мои объявления
    </NavLink>
  )
}

export const MyOrdersPageNavLink = ({ withIcon }) => {
  return (
    <NavLink to={MY_ORDERS_PAGE} className={styles.menuLink} activeClassName={styles.selectedMenu}>
      {withIcon && <CartIcon className={styles.icon}/>}Мои заказы
    </NavLink>
  )
}

export const NewAdvertPageNavLink = ({ withIcon }) => {
  return (
    <NavLink to={NEW_ADVERT_PAGE} className={styles.menuLink} activeClassName={styles.selectedMenu}>
      {withIcon && <NewAdsIcon className={styles.icon}/>} Новое
    </NavLink>
  )
}

export const NewAdvertsModerationPageNavLink = () => {
  return (
    <NavLink to={NEW_ADVERTS_MODERATION_PAGE} className={styles.menuLink} activeClassName={styles.selectedMenu}>
      Модерирование
    </NavLink>
  )
}

const Menu = ({ className, linkClassName = '', selectedMenuClassName = '' }) => {
  return (
    <ul className={`${className} ${styles.hzList}`}>
      {menuList.map(({ id, link, title }) => (
        <li key={id}>
          <NavLink to={link} className={linkClassName} activeClassName={selectedMenuClassName}>
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default Menu
