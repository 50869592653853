import React from 'react'
import styles from './AuthForm.module.scss'
import FormItem from '../FormItem'
import Input from '../Input'
import Button from '../Button'
import Form from '../Form'

const RegistrationForm = ({ error, form, onSubmit, handleShowLoginForm }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.h1}>Регистрация в сервисе</div>
        <div className={styles.h2}>Введите необходимые данные</div>
      </div>
      <Form form={form}>
        <FormItem name="firstname" label="Имя" labelCN={styles.formItem}>
          <Input iconType="check" />
        </FormItem>
        <FormItem label="Фамилия" name="lastname" labelCN={styles.formItem}>
          <Input iconType="check" />
        </FormItem>
        <FormItem label="E-mail" name="email" labelCN={styles.formItem}>
          <Input iconType="check" type="email" />
        </FormItem>
        <FormItem label="Пароль" name="password" labelCN={styles.formItem}>
          <Input iconType="eye" type="password" />
        </FormItem>
        <div className={styles.submit}>
          <Button onClick={onSubmit}>регистрация</Button>
          <div className={styles.regBtn} onClick={handleShowLoginForm}>
            Вход
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </Form>
    </>
  )
}

export default RegistrationForm
