import React from 'react'
import { Route } from 'react-router-dom'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import PrivateRoute from "./PrivateRoute";

// https://medium.com/javascript-in-plain-english/simple-guide-for-layouts-in-react-router-e32b26c12cee
const RouteLayout = ({
  isPrivate = false,
  layout: Layout,
  component: Component,
  title,
  ...rest
}) => {
  useDocumentTitle(title)
  const RouteComponent = isPrivate ? PrivateRoute : Route

  return (
    <RouteComponent {...rest}>
      <Layout>
        <Component />
      </Layout>
    </RouteComponent>
  )
}

export default RouteLayout
