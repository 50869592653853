export const getAccessToken = () => localStorage.getItem('accessToken')

export const setAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', `Bearer ${accessToken}`)
}

export const removeAccessToken = () => {
  localStorage.removeItem('accessToken')
}

export const getUser = () => {
  const user = localStorage.getItem('user')
  return user ? JSON.parse(user) : undefined
}

export const setUser = (data) => {
  localStorage.setItem('user', JSON.stringify(data))
}

export const removeUser = () => {
  localStorage.removeItem('user')
}

export const getRefreshToken = () => localStorage.getItem('refreshToken')

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refreshToken', refreshToken)
}

export const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken')
}

export const destroySession = () => {
  removeAccessToken()
  removeRefreshToken()
}

export default {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  destroySession,
  removeUser,
  setUser,
  getUser,
}
