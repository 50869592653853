import React, { useCallback, useContext, useState } from 'react'
import _pick from 'lodash/pick'
import { ApiContext } from '../../containers/ApiContainer'
import LoginForm from './LoginForm'
import LoggedInForm from './LoggedInForm'
import RegistrationForm from './RegistrationForm'
import { isEmail } from '../../utils/isEmail'
import { useForm } from '../../hooks/useForm'

const AuthForm = ({ onFinish }) => {
  const { loginUser, postUser, user } = useContext(ApiContext)
  const [isShowLoginForm, setIsShowLoginForm] = useState(true)
  const [error, setError] = useState('')
  const form = useForm(user)

  const handleSubmit = useCallback(() => {
    // TODO: add joi validation
    setError(undefined)
    if (isShowLoginForm) {
      const formField = _pick(form.state, ['email', 'password'])
      if (
        Object.values(formField).filter((el) => el.length > 0).length === 2 &&
        isEmail(formField.email)
      ) {
        loginUser(formField)
          .then(onFinish)
          .catch((e) => setError(e?.data?.error))
      }
    } else {
      if (
        Object.values(form.state).filter((el) => el.length > 0).length === 4 &&
        isEmail(form.state.email)
      ) {
        postUser(form.state)
          .then(onFinish)
          .catch((e) => setError(e?.data?.error))
      }
    }
  }, [form, isShowLoginForm, loginUser, onFinish, postUser])

  const handleShowForm = useCallback(() => {
    if (error) setError(undefined)
    setIsShowLoginForm((s) => !s)
  }, [error])

  if (user)
    return (
      <LoggedInForm
        user={user}
        onFinish={onFinish}
      />
    )

  return isShowLoginForm ? (
    <LoginForm
      error={error}
      form={form}
      onSubmit={handleSubmit}
      onFinish={onFinish}
      handleShowRegistrationForm={handleShowForm}
    />
  ) : (
    <RegistrationForm
      error={error}
      form={form}
      onSubmit={handleSubmit}
      handleShowLoginForm={handleShowForm}
    />
  )
}

export default AuthForm
