import { useCallback, useContext, useEffect } from 'react'
import { FormContext } from './useForm'

export const useFormItem = (name, initialValue) => {
  const { state, setState } = useContext(FormContext)

  useEffect(() => {
    if (name && state[name] === undefined) setState({ [name]: initialValue || '' })
  }, [state, name, setState, initialValue])

  const onChange = useCallback(
    (value) => {
      setState({ [name]: value })
    },
    [setState, name]
  )

  return { onChange, state }
}
