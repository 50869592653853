import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import GridContainerBlock from '../../components/GridContainerBlock'
import RowForAdv from '../../components/RowForAdv'
import Adv from '../../components/Adv'
import TextButton from '../../components/TextButton'
import Title from '../../components/Title'
import AdvRowBlock from '../../components/AdvRowBlock'
import AdvImage from '../../components/AdvImage'
import AdvInfoBlock from '../../components/AdvInfoBlock'
import DeleteActionBlock from '../../components/DeleteActionBlock'
import Pagination from '../../components/Pagination'
import SpinnerBlock from "../../components/SpinnerBlock";
import { ApiContext } from '../../containers/ApiContainer'
import { EDIT_ADVERT_PAGE, MESSENGER_PAGE, HOME_PAGE } from '../../constants'
import { getFormattedDate } from '../../utils/dateFotmater'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import { SELL } from "../MessengerPage";

const MyAdsPage = () => {
  const {
    user,
    loadMyAdverts,
    updateProduct,
    isLoading,
    adverts: { products, summary },
    getUserStats,
  } = useContext(ApiContext)
  const history = useHistory()

  useEffect(() => {
    if (user) {
      loadMyAdverts()
      getUserStats()
    } else {
      showPleaseLogInError()
      history.push(HOME_PAGE)
    }
  }, [user, history, loadMyAdverts, getUserStats])

  const handleClick = useCallback(
    (e) => {
      const { statusId, uuid, buyerUuid } = e.target.dataset
      if (statusId === '200') {
        return history.push(EDIT_ADVERT_PAGE.replace(':uuid', uuid))
      } else if (statusId === 'msg') {
        return history.push(`${MESSENGER_PAGE}?productUuid=${uuid}&tab=${SELL}&buyerUuid=${buyerUuid}`)
      }
      const body = { product: { aProductStatusId: statusId } }
      updateProduct(uuid, body, loadMyAdverts)
    },
    [history, loadMyAdverts, updateProduct]
  )

  const renderActionBlock = (status, uuid, buyerUuid) => {
    switch (status) {
      case 'completed':
        return (
          <>
            <TextButton
              data-status-id="900"
              data-uuid={uuid}
              onClick={handleClick}
              color="DarkBlue"
            >
              архивировать
            </TextButton>
            <TextButton data-status-id="msg" data-uuid={uuid} data-buyer-uuid={buyerUuid} onClick={handleClick} color="Blue">
              связаться с покупателем
            </TextButton>
          </>
        )
      case 'reserved':
        return (
          <>
            <TextButton data-status-id="550" data-uuid={uuid} onClick={handleClick} color="Red">
              деактивировать
            </TextButton>
            <TextButton data-status-id="700" data-uuid={uuid} onClick={handleClick} color="Green">
              выполнен
            </TextButton>
            <TextButton data-status-id="msg" data-uuid={uuid} data-buyer-uuid={buyerUuid} onClick={handleClick} color="Blue">
              связаться с покупателем
            </TextButton>
          </>
        )
      case 'published':
        return (
          <>
            <TextButton data-status-id="550" data-uuid={uuid} onClick={handleClick} color="Red">
              деактивировать
            </TextButton>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue">
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      case 'pending':
        return (
          <>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue">
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      case 'inactive':
        return (
          <>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue">
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      case 'blocked':
        return (
          <>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue">
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      case 'draft':
        return (
          <>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue">
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      case 'created':
        return (
          <>
            <TextButton data-status-id="200" data-uuid={uuid} onClick={handleClick} color="DarkBlue" >
              редактировать
            </TextButton>
            <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
              Удалить
            </TextButton>
          </>
        )
      // case 'draft':
      //   return (
      //     <>
      //       <TextButton
      //         data-status-id="200"
      //         data-uuid={uuid}
      //         onClick={handleClick}
      //         color="DarkBlue"
      //       >
      //         редактировать
      //       </TextButton>
      //       <TextButton data-status-id="800" data-uuid={uuid} onClick={handleClick} color="Red">
      //         Удалить
      //       </TextButton>
      //       <TextButton data-status-id="300" data-uuid={uuid} onClick={handleClick} color="Orange">
      //         опубликовать (с проверкой)
      //       </TextButton>
      //     </>
      //   )
      default:
        return ''
    }
  }

  if (isLoading) return <SpinnerBlock />

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="Мои Объявления" />
      </ContentBlock>
      {!products?.length && (
        <RowForAdv>
          <ContentBlock noMargin>Вы еще не разместили объявления</ContentBlock>
        </RowForAdv>
      )}
      {products.map(({ status, ...adv }) => (
        <RowForAdv key={adv.uuid}>
          <ContentBlock noMargin>
            <AdvRowBlock>
              <AdvImage>
                <Adv {...adv} />
              </AdvImage>
              <AdvInfoBlock>
                <Title text={adv.name} data-uuid={adv.uuid}/>
                <div>
                  <div>Создано:&nbsp;{getFormattedDate(adv?.createdAt)}</div>
                  {adv?.publishedAt && (
                    <div>Опубликовано:&nbsp;{getFormattedDate(adv?.publishedAt)}</div>
                  )}
                  {adv?.author && (
                    <div>Автор: {adv.author}</div>
                  )}
                </div>
                <div>
                  Статус: <strong>{status?.name}</strong>
                </div>
                {adv?.buyer && (
                  <div>
                    <div>Покупатель: {adv.buyer.firstname}
                    {adv?.buyer?.address?.city && (
                      <span>({adv.buyer.address.ciry})</span>
                    )}
                    </div>
                  </div>
                )}
              </AdvInfoBlock>
              <DeleteActionBlock>{renderActionBlock(status?.key, adv.uuid, adv?.buyer?.uuid)}</DeleteActionBlock>
            </AdvRowBlock>
          </ContentBlock>
        </RowForAdv>
      ))}
      {summary?.pages > 1 && (
        <RowForAdv>
          <ContentBlock noMargin>
            <Pagination totalPages={summary.pages} currentPage={summary.page} />
          </ContentBlock>
        </RowForAdv>
      )}
    </GridContainerBlock>
  )
}

export default MyAdsPage
