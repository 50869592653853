import joi from 'joi'

export const formSchema = joi.object({
  email: joi.string().pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    .required()
    .label('Email')
    .messages({
      'string.pattern.base': '{#label} must be valid', // --> Email must be valid.
      'string.base': '{#label} should be a type of "text"',
    })
})
