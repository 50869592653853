import React from 'react'
import styles from './Message.module.scss'
import DoubleCheckIcon from '../../images/icons/DoubleCheckSvgIcon.svg'
import DoubleCheckBlueIcon from '../../images/icons/DoubleCheckBlueSvgIcon.svg'
import { getFormattedDate } from '../../utils/dateFotmater'

const Message = ({ uuid, sender, body, read, createdAt, isMyMessage }) => {
  return (
    <div
      key={uuid}
      className={`${styles.message} ${
        isMyMessage ? styles.question : styles.answer
      }`}
    >
      <div className={styles.msgSenderName}>
        {isMyMessage ? (
          'me'
        ) : (
          `${sender.firstname}`
        )}
      </div>
      <div className={styles.msgDate}>
        <span>{getFormattedDate(createdAt)}</span>
        {read ? (
          <DoubleCheckBlueIcon className={styles.msgIcon} />
        ) : (
          <DoubleCheckIcon className={styles.msgIcon} />
        )}
      </div>

      <div className={styles.msgText}>{body}</div>
    </div>
  )
}

export default Message