import React from 'react'
import styles from './Modal.module.scss'

const Modal = ({ onClose, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.shadow} onClick={onClose} />
      <div className={styles.block}>
        <div className={styles.close}>
          <span className={styles.closeButton} onClick={onClose}/>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
