export const getFormattedDate = (dateAsStr, locale = 'ru-RU') => {
  if (!dateAsStr) return ''
  const date = new Date(dateAsStr)
  if (date instanceof Date && !isNaN(date)) {
    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
    return date.toLocaleDateString(locale, options)
  }

  throw new Error('Date is not valid')
}
