import React, { useContext } from 'react'
import styles from './Photo.module.scss'
import PhotoIcon from '../../images/icons/photoSvgIcon.svg'
import CancelIcon from '../../images/icons/cancelSvgIcon.svg'
import { ApiContext } from '../../containers/ApiContainer'

const Photo = ({ disabled = false, imageId, id, url, onAdd, empty = false }) => {
  const { uploadImage, deleteImageById } = useContext(ApiContext)

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length) {
      ;[...e.target.files].forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        uploadImage(formData).then((res) => {
          if (res) onAdd((s) => [...s, res])
        })
      })
    }
  }

  const handleDelete = () => {
    deleteImageById(imageId || id)
    onAdd((state) => {
      const index = state.findIndex((el) => (el.imageId || el.id) === (imageId || id))
      state.splice(index, 1)
      return [...state]
    })
  }

  return (
    <div className={`${styles.container} ${empty ? styles.opacity05 : ''}`}>
      <input
        type="file"
        onChange={handleOnChange}
        multiple
        disabled={disabled}
        name="photo[]"
        id={`photo${empty ? '_empty' : (imageId || id)}`}
        accept="image/*"
        className={styles.photoInput}
      />
      <label
        htmlFor={`photo${empty ? '_empty' : (imageId || id)}`}
        className={`${styles.photoIcon} ${!!(imageId || id) ? styles.labelImgPreview : ''}`}
      >
        {!(imageId || id) ? (
          <PhotoIcon />
        ) : (
          (
            <img
              alt=""
              src={url.includes('https') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`}
              className={styles.imgPreview}
            />
          )
        )}
      </label>
      {!empty && !!(imageId || id) && (
        <div className={styles.cancelIconWrapper}>
          <CancelIcon onClick={handleDelete} className={styles.cancelIcon} />
        </div>
      )}
    </div>
  )
}

export default React.memo(Photo)
