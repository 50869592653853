import { useContext, useMemo } from "react";
import { ApiContext } from "../containers/ApiContainer";

export const useUser = () => {
  const { user } = useContext(ApiContext)
  return useMemo(() => ({
    user,
    isUserLoggedIn: !!user
  }), [user])
}
