import React from 'react'
import styles from './SpinnerWrapper.module.scss'

const SpinnerWrapper = ({ children, isLoading = false }) => {
  return (
    <div className={styles.wrapper}>
      <div className={isLoading ? styles.bg : ''}>
        {isLoading && <div className={styles.loader} />}
      </div>
      {children}
    </div>
  )
}

export default SpinnerWrapper
