import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUserIsAdmin } from '../hooks/useUserIsAdmin'

const PrivateRoute = ({ children, ...rest }) => {
  const isAdmin = useUserIsAdmin()

  return (
    <Route {...rest} render={() => (isAdmin ? children : <Redirect to={{ pathname: '/' }} />)} />
  )
}

export default PrivateRoute
