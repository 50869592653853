export const withTryCatch = function (cb) {
  return async function(...arg) {
    try {
      return await cb(...arg)
    } catch (e) {
      console.error('Request error: ', e)
      return await cb(e)
    }
  }
}
