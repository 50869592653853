import React from 'react'
import styles from './RuleBlock.module.scss'
import commonStyles from '../../styles/common.module.scss'

/**
 * @param text {String | jsx}
 * @param number {Number | String}
 * @param color {"Red"|"Blue"|"Green"|"DarkYellow"}
 */
const RuleBlock = ({ text, number, color }) => {
  const textColor = `color${color}`
  const bgColor = `bg-${textColor}`

  return (
    <div className={styles.container}>
      <div className={`${styles.circle} ${commonStyles[textColor]}`}>{number}</div>
      <div className={`${styles.rectangle} ${commonStyles[bgColor]}`}>
        <div className={styles.text}>{text}</div>
        <div className={`${styles.square} ${commonStyles[bgColor]}`} />
      </div>
    </div>
  )
}

export const RuleBlock1 = () => (
  <RuleBlock
    number="01"
    color="Green"
    text={
      <>
        Ввести <br />
        ISBN/EAN товара
      </>
    }
  />
)

export const RuleBlock2 = () => (
  <RuleBlock
    number="02"
    color="DarkYellow"
    text={
      <>
        Нажать кнопку <br />
        «Автозаполнение»
      </>
    }
  />
)

export const RuleBlock3 = () => (
  <RuleBlock
    number="03"
    color="Red"
    text={
      <>
        Bookashka Shop <br />
        постарается <br />
        подгрузить <br />
        атрибуты товара
      </>
    }
  />
)

export const RuleBlock4 = () => (
  <RuleBlock
    number="04"
    color="Blue"
    text={
      <>
        Загрузить <br />
        фотографию товара
      </>
    }
  />
)

export const RuleBlock5 = () => (
  <RuleBlock
    number="05"
    color="Green"
    text={
      <>
        Ввести состояние
        <br />
        и местоположение
        <br />
        товара
      </>
    }
  />
)

export const RuleBlock6 = () => (
  <RuleBlock
    number="06"
    color="DarkYellow"
    text={
      <>
        Опубликовать <br />
        объявление
      </>
    }
  />
)
