import React, { useCallback, useReducer } from 'react'
import { api } from '../api'
import { getUser, setAccessToken, setUser, destroySession, removeUser } from '../utils/session'
import { createQueryStringFromObject } from '../utils/createQueryStringFromObject'
import { withTryCatch } from '../utils/withTryCatch'

const defaultState = {
  homeProducts: { products: [], summary: {} },
  isLoading: false,
  advCard: {
    seller: {},
    categories: [],
    delivery: {},
    condition: {},
    similarProducts: [],
  },
  wishList: {
    products: [],
    summary: undefined,
    count: 0,
  },
  adverts: {
    products: [],
    summary: undefined,
  },
  currentProduct: null,
  pendingProducts: [],
  orders: {
    orders: [],
    summary: undefined,
  },
  messages: {
    buying: [],
    buyingCount: 0,
    selling: [],
    sellingCount: 0,
    listByUUID: {},
  },
  categories: [],
  manufacturers: [],
  countries: [],
  zones: [],
  deliveries: [],
  conditions: [],
  user: getUser(),
}

const addNewMsgToSection = (state, action, section) => {
  const sectionField = state.messages[section]
  const { uuid } = action.payload
  const isConversationPresent = sectionField.some((el) => el.uuid === uuid)
  if (!isConversationPresent) return [action.payload, ...sectionField]
  return sectionField
}

const appReducer = (state, action) => {
  switch (action.type) {
    case 'isLoading':
      return { ...state, isLoading: action.payload }
    case 'home': {
      const { isShowMore, data } = action.payload
      const { summary, products } = data || {}
      return {
        ...state,
        homeProducts: {
          products: isShowMore ? [...state.homeProducts.products, ...products] : products,
          summary,
        },
      }
    }
    case 'advCard':
      return { ...state, advCard: { ...state.advCard, ...action.payload } }
    case 'similarProducts': {
      return {
        ...state,
        advCard: { ...state.advCard, similarProducts: action.payload?.products || [] },
      }
    }
    case 'user':
      return { ...state, user: action.payload }
    case 'wishList':
      return {
        ...state,
        wishList: {
          ...state.wishList,
          count: action.payload.summary.total,
          summary: action.payload.summary,
          products: action.payload.products,
        },
      }
    case 'wishListCount':
      return { ...state, wishList: { ...state.wishList, count: action.payload } }
    case 'adverts':
      return { ...state, adverts: action.payload }
    case 'currentProduct':
      return { ...state, currentProduct: action.payload }
    case 'pendingProducts':
      return { ...state, pendingProducts: action.payload }
    case 'orders':
      return { ...state, orders: action.payload }
    case 'buyingCount':
      return { ...state, messages: { ...state.messages, buyingCount: action.payload || 0 } }
    case 'buying':
      return { ...state, messages: { ...state.messages, buying: action.payload.conversations } }
    case 'buying2':
      return {
        ...state,
        messages: {
          ...state.messages,
          buying: addNewMsgToSection(state, action, 'buying')
        },
      }
    case 'sellingCount':
      return { ...state, messages: { ...state.messages, sellingCount: action.payload || 0 } }
    case 'selling':
      return { ...state, messages: { ...state.messages, selling: action.payload.conversations } }
    case 'selling2':
      return {
        ...state,
        messages: {
          ...state.messages,
          selling: addNewMsgToSection(state, action, 'selling')
        },
      }
    case 'categories':
      return { ...state, categories: action.payload }
    case 'deliveries':
      return { ...state, deliveries: action.payload }
    case 'conditions':
      return { ...state, conditions: action.payload }
    case 'manufacturers':
      return { ...state, manufacturers: action.payload }
    case 'countries':
      return { ...state, countries: action.payload }
    case 'zones': {
      const zones = [state.user.address?.zone, ...action.payload]
      if (!state.user.address?.zone) zones.shift()
      return {
        ...state,
        zones,
      }
    }
    case 'messages':
      return {
        ...state,
        messages: {
          ...state.messages,
          listByUUID: {
            ...state.messages.listByUUID,
            [action.payload.uuid]: action.payload.data.messages,
          },
        },
      }
    case 'addMessages':
      const prevMsgs = state.messages.listByUUID[action.payload.uuid] || []
      return {
        ...state,
        messages: {
          ...state.messages,
          listByUUID: {
            ...state.messages.listByUUID,
            [action.payload.uuid]: [...prevMsgs, action.payload.message],
          },
        },
      }
    default:
      return state
  }
}

const API_VERSION = '/v1'

export const ApiContext = React.createContext()

export const ApiContainer = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, defaultState, (state) => state)

  const loadHomeProductsList = useCallback(
    withTryCatch(async ({ isShowMore, ...params }) => {
      const queryParams = createQueryStringFromObject(params)
      const { data } = await api(dispatch).get(`${API_VERSION}/products${queryParams}`)
      dispatch({ type: 'home', payload: { isShowMore, data } })
    }),
    []
  )

  const logOut = useCallback(
    withTryCatch(async (uuid) => {
      dispatch({ type: 'user', payload: undefined })
      destroySession()
      removeUser()
    }),
    []
  )

  const loadAdvById = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}`)
      dispatch({ type: 'advCard', payload: data })
    }),
    []
  )

  const getUserStats = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/users/stats`)
      dispatch({ type: 'wishListCount', payload: data.wishlist })
      dispatch({ type: 'buyingCount', payload: data.unreadMessagesFromBuyersCount })
      dispatch({ type: 'sellingCount', payload: data.unreadMessagesFromSellersCount })
    }),
    []
  )

  const postUser = useCallback(
    withTryCatch(async (body) => {
      const { data, rest } = await api(dispatch).post(`/auth`, body)
      dispatch({ type: 'user', payload: data })
      setAccessToken(rest.jwt)
      setUser(data)
    }),
    []
  )

  const loginUser = useCallback(
    withTryCatch(async (body) => {
      const {
        data,
        rest: { jwt },
      } = await api(dispatch).post(`/auth/login`, body)
      dispatch({ type: 'user', payload: data })
      setAccessToken(jwt)
      setUser(data)
    }),
    []
  )

  const passwordResetRequest = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset_request`, body)
      if (cb) cb()
    }),
    []
  )

  const passwordResetValidate = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset_validate`, body)
      if (cb) cb()
    }),
    []
  )

  const passwordReset = useCallback(
    withTryCatch(async (payload) => {
      const { body, cb } = payload
      await api(dispatch).post(`/auth/password_reset`, body)
      if (cb) cb()
    }),
    []
  )

  const loadWishList = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/wishlist`)
      dispatch({ type: 'wishList', payload: data })
    }),
    []
  )

  const addToWishList = useCallback(
    withTryCatch(async (payload) => {
      const { uuid, cb } = payload
      const { data } = await api(dispatch, false).get(
        `${API_VERSION}/products/${uuid}/wishlists/add`
      )
      if (cb) cb()
      dispatch({ type: 'wishListCount', payload: data.wishlisted })
    }),
    []
  )

  const removeFromWishList = useCallback(
    withTryCatch(async (payload) => {
      const { uuid, cb } = payload
      const { data } = await api(dispatch, false).get(
        `${API_VERSION}/products/${uuid}/wishlists/remove`
      )
      if (cb) cb()
      dispatch({ type: 'wishListCount', payload: data.wishlisted })
    }),
    []
  )

  const loadMyAdverts = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/my`)
      dispatch({ type: 'adverts', payload: data })
    }),
    []
  )

  const createProduct = useCallback(
    withTryCatch(async (body, cb) => {
      await api(dispatch).post(`${API_VERSION}/products`, body)
      // dispatch({ type: 'adverts', payload: data })
      if (cb) cb()
    }),
    []
  )

  const getProduct = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}`)
      dispatch({ type: 'currentProduct', payload: data })
    }),
    []
  )

  const getPendingProducts = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/pending`)
      dispatch({ type: 'pendingProducts', payload: data })
    }),
    []
  )

  const loadSimilarProductsById = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/${uuid}/similar_products`)
      dispatch({ type: 'similarProducts', payload: data })
    }),
    []
  )

  const updateProduct = useCallback(
    withTryCatch(async (uuid, body, cb) => {
      await api(dispatch).put(`${API_VERSION}/products/${uuid}`, body)
      // dispatch({ type: 'adverts', payload: data })
      if (cb) cb()
    }),
    []
  )

  const deleteProduct = useCallback(
    withTryCatch(async (uuid) => {
      await api(dispatch).delete(`${API_VERSION}/products/${uuid}`)
      // dispatch({ type: 'adverts', payload: data })
    }),
    []
  )

  const findProductByGTIN = useCallback(
    withTryCatch(async (gtin) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/products/locate?gtin=${gtin}`) //"978-5-907143-26-5"
      const manufacturer = [data.manufacturer]
      const response = {
        ...data,
        manufacturer,
      }
      dispatch({ type: 'manufacturers', payload: manufacturer })
      return response
    }),
    []
  )

  const loadOrders = useCallback(
    withTryCatch(async (payload) => {
      // const { data } = await api(dispatch).get(`${API_VERSION}/orders/${userUUID}`)
      const { data } = await api(dispatch).get(`${API_VERSION}/orders?filter=${payload}`)
      dispatch({ type: 'orders', payload: data })
    }),
    []
  )

  const postOrder = useCallback(
    withTryCatch(async (payload) => {
      return await api(dispatch).post(`${API_VERSION}/orders`, payload)
    }),
    []
  )

  const updateOrders = useCallback(
    withTryCatch(async (uuid, status, cb) => {
      await api(dispatch).put(`${API_VERSION}/orders/${uuid}?act=${status}`)
      if (cb) cb()
    }),
    []
  )

  const updateUser = useCallback(
    withTryCatch(async (uuid, body, cb) => {
      const { data } = await api(dispatch).put(`${API_VERSION}/users/${uuid}`, body)
      dispatch({ type: 'user', payload: data })
      setUser(data)
      if (cb) cb()
    }),
    []
  )

  const loadBuyingMessages = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/buying`)
      dispatch({ type: 'buying', payload: data })
    }),
    []
  )

  const loadMessagesByProductIdAndBuyerId = useCallback(
    withTryCatch(async ({ type, ...payload }) => {
      const { data } = await api(dispatch).post(`${API_VERSION}/conversations`, payload)
      dispatch({ type, payload: data })
    }),
    []
  )

  const loadSellingMessages = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/selling`)
      dispatch({ type: 'selling', payload: data })
    }),
    []
  )

  const loadMessages = useCallback(
    withTryCatch(async (uuid) => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conversations/${uuid}/messages`)
      dispatch({ type: 'messages', payload: { uuid, data } })
    }),
    []
  )

  const sendMessages = useCallback(
    withTryCatch(async (uuid, body) => {
      const { data } = await api(dispatch).post(
        `${API_VERSION}/conversations/${uuid}/messages`,
        body
      )
      dispatch({ type: 'addMessages', payload: { uuid, message: data } })
    }),
    []
  )

  const loadCategories = useCallback(
    withTryCatch(async (term) => {
      const { data } = await api(dispatch).get(
        `${API_VERSION}/categories${term ? `?term=${term}` : ''}`
      )
      dispatch({ type: 'categories', payload: data })
    }),
    []
  )

  const loadManufacturers = useCallback(
    withTryCatch(async (term) => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/manufacturers?term=${term}`)
      dispatch({ type: 'manufacturers', payload: data.manufacturers })
    }),
    []
  )

  const loadCountries = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/countries`)
      dispatch({ type: 'countries', payload: data.countries })
    }),
    []
  )

  const loadZonesByCountryId = useCallback(
    withTryCatch(async (countryId) => {
      const { data } = await api(dispatch, false).get(`${API_VERSION}/countries/${countryId}/zones`)
      dispatch({ type: 'zones', payload: data.zones })
    }),
    []
  )

  const loadDeliveries = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/deliveries`)
      dispatch({ type: 'deliveries', payload: data.deliveries })
    }),
    []
  )

  const loadConditions = useCallback(
    withTryCatch(async () => {
      const { data } = await api(dispatch).get(`${API_VERSION}/conditions`)
      dispatch({ type: 'conditions', payload: data.conditions })
    }),
    []
  )

  const deleteImageById = useCallback(
    withTryCatch(async (id) => {
      await api(dispatch).delete(`${API_VERSION}/images/${id}`)
    }),
    []
  )

  const uploadImage = useCallback(
    withTryCatch(async (file) => {
      const { rest } = await api(dispatch).put(`${API_VERSION}/images/upload`, file, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      return rest
    }),
    []
  )

  return (
    <ApiContext.Provider
      value={{
        ...state,
        createProduct,
        deleteImageById,
        deleteProduct,
        findProductByGTIN,
        getProduct,
        getPendingProducts,
        loadAdvById,
        loadBuyingMessages,
        loadCategories,
        loadConditions,
        loadDeliveries,
        loadHomeProductsList,
        loadManufacturers,
        loadCountries,
        loadZonesByCountryId,
        loadMessages,
        loadMyAdverts,
        loadOrders,
        loadSellingMessages,
        loadSimilarProductsById,
        loadMessagesByProductIdAndBuyerId,
        loadWishList,
        sendMessages,
        addToWishList,
        removeFromWishList,
        getUserStats,
        loginUser,
        logOut,
        postOrder,
        postUser,
        updateOrders,
        updateProduct,
        updateUser,
        uploadImage,
        passwordResetRequest,
        passwordResetValidate,
        passwordReset,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
