import React from 'react'

import styles from './Footer.module.scss'
import logoImg from '../../images/logo.png'
import Menu from '../Menu'
import { BOOKASHKA_HOME_PAGE } from '../../constants'

const Footer = ({ className }) => {
  return (
    <footer className={`${className} ${styles.footer}`}>
      <div className={styles.footerContent}>
        <div className={styles.footerWrapper}>
          <a target="_blank" href={BOOKASHKA_HOME_PAGE} rel="noopener noreferrer" >
            <img src={logoImg} className={styles.logo} alt="logo" title="logo" />
          </a>
          <Menu
            className={styles.sections}
            linkClassName={styles.menuLink}
            selectedMenuClassName={styles.selectedMenu}
          />
          <div className={styles.social}>
            <a className={styles.mailUs} href="mailto:adv@bookashka.co.uk">
              adv@bookashka.co.uk
            </a>
            <div className={styles.inSocial}>Мы в социальных сетях</div>
            <a href="https://www.facebook.com/Bookashkashop" target="_blank" rel="noopener noreferrer" >
              <span className={styles.facebook} />
            </a>
            <a href="https://www.instagram.com/Bookashkashop" target="_blank" rel="noopener noreferrer" >
              <span className={styles.instagram} />
            </a>
          </div>
        </div>
        <div className={styles.disclaimer}>
          Copyright © 2021 Bookashka.co.uk | Детские книги на русском языке
        </div>
      </div>
    </footer>
  )
}

export default Footer
