import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react'
import styles from './Input.module.scss'
import { isEmail } from '../../utils/isEmail'
import { useFormItem } from '../../hooks/useFormItem'

/**
 * @param useHook {Boolean}
 * @param iconType {'eye'|'check'|''}
 * @param suffix {String}
 * @param name {String}
 * @param autoFocus {Boolean}
 * @param suffixOnClick {Function}
 */
const Input = ({ useHook = false, inputCN, inputRowCN, iconType = '', suffix, suffixOnClick, ...rest }) => {
  const inputRef = useRef()
  const [additionalProps, setAdditionalProps] = useState({})
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isValidIcon, setIsValidIcon] = useState(false)
  const { onChange: hookOnChange, state } = useFormItem(rest.name)
  const props = useMemo(
    () => ({
      ...rest,
      onChange: useHook ? hookOnChange : rest.onChange,
      value: useHook ? state[rest.name] || '' : rest.value,
      ...(rest.type === 'checkbox'
        ? { checked: useHook ? state[rest.name] || false : rest.value }
        : {}),
    }),
    [hookOnChange, rest, state, useHook]
  )
  const showPassword = () => {
    setAdditionalProps({ type: !isShowPassword ? 'text' : props.type })
    setIsShowPassword((s) => !s)
  }

  useEffect(() => {
    if (props.autoFocus) inputRef.current.focus()
    // imitation of didMount method
  }, []) // eslint-disable-line

  useEffect(() => {
    if (props.type === 'email') {
      if (isEmail(props.value)) {
        setIsValidIcon(true)
      } else {
        setIsValidIcon(false)
      }
    }

    if (props.type !== 'email' && props.type !== 'password') {
      if (props?.value?.length > 3) {
        setIsValidIcon(true)
      } else {
        setIsValidIcon(false)
      }
    }
    // eslint-disable-next-line
  }, [props.type, props.value])

  const handleOnChange = useCallback(
    (e) => {
      const value = props.type === 'checkbox' ? e.target.checked : e.target.value
      props.onChange(value)
    },
    [props]
  )

  return (
    <div className={`${styles.row}${inputRowCN ? ` ${inputRowCN}` : ''}`}>
      <input
        {...props}
        {...additionalProps}
        onChange={handleOnChange}
        ref={inputRef}
        className={`${styles.input}${inputCN ? ` ${inputCN}` : ''}`}
      />
      <span
        data-correct={isShowPassword || isValidIcon}
        className={`${styles.btn} ${suffix ? styles.suffix : ''} ${styles[iconType] || ''} ${
          iconType === 'eye' ? styles.cp : ''
        }`}
        onClick={iconType === 'eye' ? showPassword : suffixOnClick}
      >
        {suffix}
      </span>
    </div>
  )
}

export default React.memo(Input)
