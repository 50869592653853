import React, { useCallback, useContext, useEffect } from 'react'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import Title from '../../components/Title'
import styles from './ProfilePage.module.scss'
import FormItem from '../../components/FormItem'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Select from '../../components/Select'
import Form from '../../components/Form'
import { useForm } from '../../hooks/useForm'
import { Link } from 'react-router-dom'
import { HOME_PAGE } from '../../constants'
import { ApiContext } from '../../containers/ApiContainer'
import { formSchema } from './formSchema'

const ProfilePage = ({ error }) => {
  const {
    loadZonesByCountryId,
    zones,
    loadCountries,
    countries,
    user,
    updateUser,
    isLoading,
  } = useContext(ApiContext)

  const form = useForm()

  const handleChangeCountry = useCallback((values) => {
    form.setState({ addressCountry: values, addressZone: [] })
    if (values.length > 0) {
      const [{ id }] = values
      loadZonesByCountryId(id)
    }
  }, [form, loadZonesByCountryId])

  useEffect(() => {
    loadCountries()
    if (user?.address?.country?.id) loadZonesByCountryId(user.address.country.id)
  }, [user?.address?.country?.id]) // eslint-disable-line

  useEffect(() => {
    if (user) {
      const { firstname, lastname, email, telephone, newsletter, address = {} } = user

      const {
        firstname: addressFirstname,
        lastname: addressLastname,
        companyName: addressCompanyName,
        address1: addressAddress1,
        address2: addressAddress2,
        city: addressCity,
        postcode: addressPostcode,
        zone: addressZone,
        country: addressCountry,
      } = address

      form.setState({
        firstname,
        lastname,
        email,
        telephone,
        newsletter: !!newsletter,
        addressFirstname,
        addressLastname,
        addressCompanyName,
        addressAddress1,
        addressAddress2,
        addressCity,
        addressPostcode,
        addressZone: addressZone ? [addressZone] : [],
        addressCountry: addressCountry ? [addressCountry] : [],
      })
    }
  }, [user]) // eslint-disable-line

  const prepareRequestBody = useCallback(() => {
    const { value, error } = formSchema.validate(form.state, {
      stripUnknown: true /*abortEarly: false*/,
    })
    if (error) {
      console.error('ValidationError:', error)
      alert(error)
      return
    }

    const {
      addressFirstname,
      addressLastname,
      addressCompanyName,
      addressAddress1,
      addressAddress2,
      addressCity,
      addressPostcode,
      addressZone,
      addressCountry,
      newsletter,
      ...rest
    } = value

    return {
      ...rest,
      // firstname: "MyNewName",
      // lastname: "MyNewLastName",
      // image: "image_url",
      // telephone: "22222",
      newsletter: newsletter ? 1 : 0,
      address: {
        address_id: user.address.addressId,
        firstname: addressFirstname,
        lastname: addressLastname,
        company: addressCompanyName,
        address_1: addressAddress1,
        address_2: addressAddress2,
        city: addressCity,
        postcode: addressPostcode,
        zone_id: addressZone ? addressZone[0].id : undefined,
        country_id: addressCountry ? addressCountry[0].id : undefined,
      },
    }
  }, [form.state, user.address.addressId])

  const handleSubmit = useCallback(() => {
    const preparedBody = prepareRequestBody()
    if (preparedBody) {
      const body = {
        user: preparedBody,
      }

      updateUser(user.uuid, body)
    }
  }, [prepareRequestBody, updateUser, user.uuid])

  if (isLoading) {
    return 'Загрузка'
  }

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="Профиль" />
        <Form form={form}>
          <Title text="Личные данные" />
          <div className={styles.formSection}>
            <FormItem label="Имя" name="firstname" labelCN={styles.formItem}>
              <Input useHook name="firstname" placeholder="Firstname" />
            </FormItem>
            <FormItem label="Фамилия" name="lastname" labelCN={styles.formItem} required={false}>
              <Input useHook name="lastname" placeholder="Lastname" />
            </FormItem>
            <FormItem label="Email" name="email" labelCN={styles.formItem}>
              <Input useHook name="email" placeholder="your@email.ru" />
            </FormItem>
            <FormItem label="Телефон" name="telephone" labelCN={styles.formItem} required={false}>
              <Input useHook name="telephone" placeholder="+44-XXX-XXX-XXXX" />
            </FormItem>
          </div>
          <Title text="Адрес" />
          <div className={styles.formSection}>
            <FormItem
              label="Имя"
              name="addressFirstname"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressFirstname" placeholder="Firstname" />
            </FormItem>
            <FormItem
              label="Фамилия"
              name="addressLastname"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressLastname" placeholder="Lastname" />
            </FormItem>
            <FormItem
              label="Название компании"
              name="addressCompanyName"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressCompanyName" placeholder="Company Name" />
            </FormItem>
          </div>
          <div className={styles.formSection}>
            <FormItem
              label="Адрес 1"
              name="addressAddress1"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressAddress1" placeholder="Address 1" />
            </FormItem>
            <FormItem
              label="Адрес 2"
              name="addressAddress2"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressAddress2" placeholder="Address 2" />
            </FormItem>
            <FormItem label="Город" name="addressCity" labelCN={styles.formItem} required={false}>
              <Input useHook name="addressCity" placeholder="City" />
            </FormItem>
            <FormItem
              label="Индекс/Почтовый Код"
              name="addressPostcode"
              labelCN={styles.formItem}
              required={false}
            >
              <Input useHook name="addressPostcode" placeholder="Postcode" />
            </FormItem>
            <FormItem
              label="Страна"
              labelCN={styles.formItem}
              required={false}
              type="select"
              value={form.state.addressCountry}
              onChange={handleChangeCountry}
            >
              <Select
                options={countries}
                placeholder="United Kingdom"
              />
            </FormItem>
            <FormItem
              name="addressZone"
              label="Регион"
              labelCN={styles.formItem}
              type="select"
              required={false}
            >
              <Select options={zones} placeholder="United Kingdom" />
            </FormItem>
          </div>
          <div className={styles.formSection}>
            <FormItem required={false}><></></FormItem>
            <FormItem
              label="Получать новости"
              name="newsletter"
              labelCN={styles.newsletterLabel}
              formItemCN={styles.newsletterFormItem}
              required={false}
            >
              <Input useHook type="checkbox" name="newsletter" inputCN={styles.newsletterInput} inputRowCN={styles.newsletterRow}/>
            </FormItem>
          </div>
          <div className={styles.submit}>
            <Button onClick={handleSubmit}>Сохранить</Button>
            <div className={styles.link}>
              <Link to={HOME_PAGE}>Отменить</Link>
            </div>
            {error && <div className={styles.error}>{error}</div>}
          </div>
        </Form>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default ProfilePage
