import React from "react";
import ContentBlock from "../ContentBlock";
import GridContainerBlock from "../GridContainerBlock";

const SpinnerBlock = () => {
  return (
    <GridContainerBlock>
      <ContentBlock>
        Loading data...
      </ContentBlock>
    </GridContainerBlock>
  );
};

export default SpinnerBlock;
