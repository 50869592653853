import React from 'react'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import styles from './Main.module.scss'

const MainLayout = ({ children }) => (
  <div className={styles.container}>
    <Header className={styles.header} />
    <main className={styles.content}>{children}</main>
    <Footer className={styles.footer} />
  </div>
)

export default MainLayout
