import React, { useCallback, useRef, useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import Slider from 'react-slick'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import GridContainerBlock from '../../components/GridContainerBlock'
import styles from './AdvCardPage.module.scss'
import { HeartButton, MessageButton, OrderButton } from '../../components/Button'
import Adv from '../../components/Adv'
import Pagination, { PageButton } from '../../components/Pagination'
import Title from '../../components/Title'
import { ApiContext } from '../../containers/ApiContainer'
import Distance from '../../components/Distance'
import LocationIcon from '../../images/icons/LocationSvgIcon.svg'
import DeliveryCarIcon from '../../images/icons/DeliveryCarSvgIcon.svg'
import { getFormattedDate } from '../../utils/dateFotmater'
import SpinnerBlock from '../../components/SpinnerBlock'
import { MESSENGER_PAGE, MY_ORDERS_PAGE } from '../../constants'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import './slider.scss'
import { BUY } from '../MessengerPage'

const NextArrow = ({ onClick }) => (
  <PageButton onClick={onClick} className={styles.nextArrow} label="»" />
)
const PrevArrow = ({ onClick }) => (
  <PageButton onClick={onClick} className={styles.prevArrow} label="«" />
)

const AdvCardPage = () => {
  const mainImageSliderRef = useRef()
  const previewImagesSliderRef = useRef()
  const {
    loadAdvById,
    postOrder,
    user,
    advCard,
    loadSimilarProductsById,
    isLoading,
    getUserStats,
    addToWishList,
    removeFromWishList,
  } = useContext(ApiContext)
  const [similarProductsState, setSimilarProductsState] = useState({
    list: [],
    totalPages: 1,
    currentPage: 1,
  })
  const { uuid } = useParams()
  const history = useHistory()

  const {
    ean,
    name,
    isbn,
    price,
    width,
    mainImage,
    seller,
    height,
    weight,
    length,
    quantity,
    delivery,
    condition,
    categories,
    description,
    manufacturer,
    similarProducts,
    publishedAt,
    wishlist,
    images = [],
  } = advCard

  const [isActive, setActive] = useState(() => wishlist)

  useEffect(() => {
    loadAdvById(uuid)
    loadSimilarProductsById(uuid)
    getUserStats()
  }, [loadAdvById, loadSimilarProductsById, uuid, getUserStats])

  useEffect(() => {
    const list = similarProducts.slice(0, 4)
    const totalPages = Math.ceil(similarProducts.length / 4)
    setSimilarProductsState({ list, totalPages, currentPage: 1 })
  }, [similarProducts])

  const handleShowNotification = useCallback((msg, title) => {
    NotificationManager.success(msg, title)
  }, [])

  const onFavoriteClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (!user) return showPleaseLogInError()

      const apiFunc = isActive ? removeFromWishList : addToWishList
      const msg = isActive ? 'удален из избранных' : 'добавлен в избранные'
      apiFunc({
        uuid,
        cb() {
          handleShowNotification(`Товар успешно ${msg}.`)
          setActive((isActive) => !isActive)
        },
      })
    },
    [user, isActive, removeFromWishList, addToWishList, uuid, handleShowNotification]
  )

  const onMessageClick = useCallback(() => {
    if (!user) return showPleaseLogInError()
    return history.push(`${MESSENGER_PAGE}?productUuid=${uuid}&tab=${BUY}`)
  }, [history, user, uuid])

  const handlePageClick = useCallback(
    (page) => {
      const start = page === 1 ? 0 : page * 4 - 4
      const end = similarProducts.length - start <= 4 ? similarProducts.length : 4
      const list = similarProducts.slice(start, end)
      setSimilarProductsState((s) => ({ ...s, list, currentPage: page }))
    },
    [similarProducts]
  )

  const goToMyOrdersPage = useCallback(() => {
    history.push(MY_ORDERS_PAGE)
  }, [history])

  const handleNewOrder = useCallback(() => {
    if (!user) return showPleaseLogInError()
    postOrder({ aProductUuid: uuid }).finally(goToMyOrdersPage)
  }, [goToMyOrdersPage, postOrder, user, uuid])

  if (isLoading) return <SpinnerBlock />

  const distance = seller?.address?.distance

  const imagesForSlider = mainImage ? [mainImage, ...images] : images

  return (
    <GridContainerBlock withBottomBanner>
      <ContentBlock noMargin>
        <PageBackTitle title="карточка товара" />
        <div className={styles.container}>
          <div className={styles.image}>
            <Slider
              asNavFor={previewImagesSliderRef.current}
              ref={mainImageSliderRef}
              arrows={false}
              swipe={false}
            >
              {imagesForSlider.map(({ url, id }) => (
                <img key={id} src={url} alt="big_preview" />
              ))}
            </Slider>
            <Slider
              asNavFor={mainImageSliderRef.current}
              ref={previewImagesSliderRef}
              infinite={false}
              focusOnSelect
              slidesToShow={imagesForSlider.length >= 4 ? 4: imagesForSlider.length}
              slidesToScroll={1}
              className={styles.slickSlide}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {imagesForSlider.map(({ previewUrl, id }) => (
                <div className={styles.slideWrapper} key={id}>
                  <img key={id} src={previewUrl} alt="preview" />
                </div>
              ))}
            </Slider>
          </div>
          <div className={styles.content}>
            <Title text={name} className={styles.relative}>
              <span className={styles.priceBlock}>
                {price}
                <Distance distance={distance} />
              </span>
            </Title>
            <div>Опубликовано: {getFormattedDate(publishedAt)}</div>
            <div className={styles.sellerInfo}>
              <div>Продавец: {seller.firstname || ''}</div>
              <div>Зарегистрирован: {getFormattedDate(seller?.dateAdded)}</div>
            </div>
            <div className={styles.location}>
              <LocationIcon title="Местоположение" alt="Местоположение" />
              <span title="Местоположение">{seller.address?.city || ''}</span>
              <DeliveryCarIcon title="Способ доставки" alt="Способ доставки" />
              <span title="Способ доставки">{delivery?.name || ''}</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.params}>
              <div className={styles.label}>Категория</div>
              <div className={styles.value}>{categories.map((el) => el.name).join(', ')}</div>
              {(isbn || ean) && (
                <>
                  <div className={styles.label}>Isbn/ean</div>
                  <div className={styles.value}>{isbn || ean}</div>
                </>
              )}
              {manufacturer && (
                <>
                  <div className={styles.label}>Производитель/Издательство</div>
                  <div className={styles.value}>{manufacturer.name}</div>
                </>
              )}
              <div className={styles.label}>состояние</div>
              <div className={styles.value}>{condition?.name || ''}</div>
              <div className={styles.label}>Количество</div>
              <div className={styles.value}>{quantity}</div>
              <div className={styles.label}>
                вес <span className={styles.blueLabel}>(гр)</span>
              </div>
              <div className={styles.value}>{weight}</div>
              <div className={styles.label}>
                габариты <span className={styles.blueLabel}>(мм)</span>
              </div>
              <div className={styles.value}>
                {width}x{length}x{height}
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.label}>Описание</div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <div className={styles.divider} />
            <div className={styles.actions}>
              {user && seller.uuid === user.uuid ? (
                <span>Мною Опубликовано</span>
              ) : (
                <>
                  <OrderButton onClick={handleNewOrder} />
                  <MessageButton onClick={onMessageClick} />
                  <HeartButton onClick={onFavoriteClick} active={isActive} />
                </>
              )}
            </div>
          </div>
        </div>
        {similarProductsState.list.length > 0 && (
          <div className={styles.similarGoods}>
            <div className={styles.similarGoodsTitle}>Мы рекомендуем</div>
            <div className={styles.goodsList}>
              {similarProductsState.list.map((el) => (
                <Adv key={el.id || el.uuid} {...el} />
              ))}
            </div>
            {similarProductsState.totalPages > 1 && (
              <div className={styles.pagination}>
                <Pagination
                  onClick={handlePageClick}
                  withPageList={false}
                  currentPage={similarProductsState.currentPage}
                  totalPages={similarProductsState.totalPages}
                />
              </div>
            )}
          </div>
        )}
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default AdvCardPage
