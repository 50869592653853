import React from 'react'
import styles from './GridContainerBlock.module.scss'
import bottomBannerImg from '../../images/banner-bottom-large.png'
import { BOOKASHKA_HOME_PAGE } from '../../constants'

const GridContainerBlock = ({ className = '', children, withBottomBanner = false }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {children}
      {withBottomBanner && (
        <div className={styles.bannerBottom}>
          <a target="_blank" href={BOOKASHKA_HOME_PAGE} rel="noopener noreferrer" >
            <img src={bottomBannerImg} alt="bottom-banner" title="bottom-banner" />
          </a>
        </div>
      )}
    </div>
  )
}

export default GridContainerBlock
