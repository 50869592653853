import joi from 'joi'

const str = joi.string()

export const formSchema = joi.object({
  code: str.allow(''),
  password: str.min(5).max(15).required(),
  passwordConfirmation: joi.any().valid(joi.ref('password'))
    .required()
    .label('Password Confirmation')
    .messages({
      'any.only': '{#label} must mutch "Password"', // --> Email must be valid.
    })
})
