import React from 'react'
import styles from './Select.module.scss'
import RDDSelect from 'react-dropdown-select'

const Select = ({ className, style = {}, ...props }) => {
  const noDataRenderer = () => {
    return <p style={{ textAlign: 'center' }}>Ничего не найдено :(</p>
  }

  const selectStyle = {
    border: 'none',
    borderBottom: '1px solid #676766',
    borderRadius: 0,
    ...style,
  }

  return (
    <RDDSelect
      className={`${styles.select}${className ? ` ${className}` : ''}`}
      noDataRenderer={noDataRenderer}
      placeholder="Выберите..."
      style={selectStyle}
      clearable
      searchable
      searchBy="name"
      valueField="id"
      labelField="name"
      {...props}
    />
  )
}

export default Select
