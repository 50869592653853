import { snakeCase, camelCase } from 'lodash'
import { isObject } from './isObject'
import { isArray } from './isArray'

export const keysToSpecificCase = (obj, caseFunction) => {
  if (isObject(obj)) {
    const n = {}

    Object.entries(obj).forEach(([k, v]) => {
      n[caseFunction(k)] = keysToSpecificCase(v, caseFunction)
    })

    return n
  } else if (isArray(obj)) {
    return obj.map((i) => {
      return keysToSpecificCase(i, caseFunction)
    })
  }

  return obj
}

export const keysToCamelCase = (obj) => keysToSpecificCase(obj, camelCase)

export const keysToSnakeCase = (obj) => keysToSpecificCase(obj, snakeCase)
