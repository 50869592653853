import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import _debounce from 'lodash/debounce'
import GridContainerBlock from '../../components/GridContainerBlock'
import PageBackTitle from '../../components/PageBackTitle'
import ContentBlock from '../../components/ContentBlock'
import styles from './NewAdvertPage.module.scss'
import {
  RuleBlock1,
  RuleBlock2,
  RuleBlock3,
  RuleBlock4,
  RuleBlock5,
  RuleBlock6,
} from '../../components/RuleBlock'
import FormItem from '../../components/FormItem'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Select from '../../components/Select'
import Button from '../../components/Button'
import TextButton from '../../components/TextButton'
import Photo from '../../components/Photo'
import Form from '../../components/Form'
import { ApiContext } from '../../containers/ApiContainer'
import { useForm } from '../../hooks/useForm'
import { formSchema } from './formSchema'
import { MY_ADS_PAGE, BOOKASHKA_HOME_PAGE } from '../../constants'
import SpinnerBlock from '../../components/SpinnerBlock'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'
import { NotificationManager } from 'react-notifications'

const NewAdvertPage = () => {
  const {
    user,
    createProduct,
    updateProduct,
    getProduct,
    currentProduct,
    findProductByGTIN,
    loadDeliveries,
    loadConditions,
    loadManufacturers,
    deliveries,
    manufacturers,
    loadCategories,
    categories,
    conditions,
    isLoading,
    getUserStats,
  } = useContext(ApiContext)
  const form = useForm({
    price: 0,
    manufacturer: [{ id: 300, name: 'Unknown' }],
  })
  const { uuid } = useParams()
  const history = useHistory()
  const [images, setImages] = useState([])
  const debouncedLoadManufacturers = _debounce(loadManufacturers, 400)

  useEffect(() => {
    if (uuid) getProduct(uuid)
    loadCategories()
    loadDeliveries()
    loadConditions()
    if (user) getUserStats()
  }, [user]) // eslint-disable-line

  useEffect(() => {
    if (uuid && currentProduct) {
      const {
        isbn,
        name,
        categories,
        description,
        condition,
        delivery,
        price,
        manufacturer,
        author,
        length,
        width,
        height,
        weight,
        images = [],
        mainImage
      } = currentProduct

      setImages(Object.keys(mainImage).length ? [mainImage, ...images] : images)

      form.setState({
        isbn,
        title: name,
        category: categories,
        description,
        productCondition: [condition],
        delivery: [delivery],
        price: price.slice(1),
        manufacturer: [manufacturer],
        authors: author,
        length: `${length}`,
        width: `${width}`,
        height: `${height}`,
        weight: `${weight}`,
      })
    }
  }, [currentProduct]) // eslint-disable-line

  const handleAutoFilling = useCallback(async () => {
    const data = await findProductByGTIN(form.state.isbn)
    form.setState(data)
  }, [findProductByGTIN, form])

  const searchManufacturer = ({ state }) => {
    debouncedLoadManufacturers(state.search)
  }

  const goToMyAdvertPage = useCallback(() => {
    history.push(MY_ADS_PAGE)
  }, [history])

  const prepareRequestBody = useCallback(() => {
    const { value, error } = formSchema.validate(form.state, {
      stripUnknown: true /*abortEarly: false*/,
    })
    if (error) {
      NotificationManager.error(error + '')
      return
    }

    const {
      manufacturer,
      title,
      delivery,
      category,
      productCondition,
      length,
      width,
      height,
      weight,
      ...rest
    } = value

    const imagesObjs = [...images]
    const aMainImageId = imagesObjs.shift()
    const [{ id, name }] = manufacturer.length > 0 ? manufacturer : [{}]
    const manufacturerName = typeof id === 'string' && id === name ? name : undefined
    const manufacturerId = !manufacturerName ? id : undefined
    return {
      ...rest,
      name: title,
      length: length || undefined,
      width: width || undefined,
      height: height || undefined,
      weight: weight || undefined,
      manufacturerId,
      manufacturerName,
      deliveryId: delivery[0].id,
      aProductConditionId: productCondition[0].id,
      categoryIds: category.map(({ id }) => id),
      aMainImageId: aMainImageId?.imageId || aMainImageId?.id,
      imageIds: imagesObjs.map(({ imageId, id }) => imageId || id),
    }
  }, [form.state, images])

  const handleMakeRequest = useCallback(
    (e) => {
      if (user) {
        const { statusId } = e.currentTarget.dataset
        const preparedBody = prepareRequestBody()
        if (preparedBody) {
          const body = {
            product: { ...preparedBody, aProductStatusId: statusId },
          }
          if (uuid) {
            updateProduct(uuid, body, goToMyAdvertPage)
          } else {
            createProduct(body, goToMyAdvertPage)
          }
        }
      } else {
        showPleaseLogInError()
      }
    },
    [createProduct, goToMyAdvertPage, prepareRequestBody, updateProduct, user, uuid]
  )

  if (isLoading) return <SpinnerBlock />

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="новое объявления" />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.col1}>
              <Form form={form}>
                <FormItem
                  name="isbn"
                  label="Ввод ISBN/EAN"
                  required={false}
                  labelCN={styles.formItem}
                >
                  <Input
                    placeholder="4623721618116"
                    suffix="автозаполнение"
                    suffixOnClick={handleAutoFilling}
                  />
                </FormItem>
                <FormItem name="title" label="название" labelCN={styles.formItem}>
                  <Input placeholder="Прогеры" />
                </FormItem>
                <FormItem
                  type="select"
                  name="category"
                  label="Выбор Категории"
                  labelCN={styles.formItem}
                >
                  <Select multi options={categories} placeholder="Выберите категорию" />
                </FormItem>
                <FormItem
                  name="description"
                  label="описание объявления"
                  required={false}
                  labelCN={styles.formItem}
                >
                  <Textarea placeholder="Введите информацию" />
                </FormItem>
                <FormItem
                  type="select"
                  name="productCondition"
                  label="состояние"
                  labelCN={styles.formItem}
                >
                  <Select options={conditions} />
                </FormItem>
                <FormItem type="select" name="delivery" label="доставка" labelCN={styles.formItem}>
                  <Select options={deliveries} />
                </FormItem>
                <FormItem justUI label="цена" labelCN={styles.formItem}>
                  <div className={styles.rowWith3Cols}>
                    <Input useHook name="price" placeholder="0,00" />
                  </div>
                </FormItem>
                <FormItem justUI label="фотография" labelCN={styles.formItem}>
                  <div className={styles.photos}>
                    {images.map((el) => (
                      <Photo onAdd={setImages} key={el.imageId || el.id} {...el} disabled />
                    ))}
                    <Photo onAdd={setImages} empty />
                  </div>
                </FormItem>
                <FormItem
                  name="manufacturer"
                  label="Производитель/Издательство"
                  labelCN={styles.formItem}
                  type="select"
                  required={false}
                >
                  <Select
                    searchFn={searchManufacturer}
                    options={manufacturers}
                    placeholder="Робинс"
                    create
                    createNewLabel="Добавить {search}"
                    className={styles.selectManufacturer}
                  />
                </FormItem>
                <FormItem name="authors" label="Автор" required={false} labelCN={styles.formItem}>
                  <Input placeholder="Иван Васильевич" />
                </FormItem>
                <FormItem justUI label="Габариты" required={false} labelCN={styles.formItem}>
                  <div className={styles.rowWith3Cols}>
                    <Input useHook name="length" placeholder="Длина (мм)" />
                    <Input useHook name="width" placeholder="Ширина (мм)" />
                    <Input useHook name="height" placeholder="Высота (мм)" />
                  </div>
                </FormItem>
                <FormItem justUI label="Вес" required={false} labelCN={styles.formItem}>
                  <div className={styles.rowWith3Cols}>
                    <Input useHook name="weight" placeholder="999 (гр)" />
                  </div>
                </FormItem>
                <div className={styles.submitBlock}>
                  <TextButton
                    data-status-id="200"
                    onClick={handleMakeRequest}
                    color="Blue"
                    className={styles.tal}
                  >
                    черновик
                  </TextButton>
                  <Button data-status-id="300" onClick={handleMakeRequest}>
                    опубликовать
                  </Button>
                  <TextButton color="Red">отменить</TextButton>
                </div>
              </Form>
            </div>
            <div className={styles.col2}>
              <h3 className={styles.h3}>Правила публикации</h3>
              <div className={styles.ruleBlock}>
                <RuleBlock1 />
                <RuleBlock2 />
                <RuleBlock3 />
                <RuleBlock4 />
                <RuleBlock5 />
                <RuleBlock6 />
              </div>
              <div className={styles.imageYellow} />
              <a target="_blank" href={BOOKASHKA_HOME_PAGE} rel="noopener noreferrer">
                <div className={styles.imageRed} />
              </a>
            </div>
          </div>
        </div>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default NewAdvertPage
