import React from 'react'
import { useForm } from '../../hooks/useForm'

const Form = ({ form = {}, children }) => {
  const { context, ...rest } = useForm()
  const { context: formContext, ...formRest } = form
  const Container = formContext || context
  const value = Object.keys(formRest).length ? formRest : rest

  return <Container.Provider value={value}>{children}</Container.Provider>
}

export default React.memo(Form)
