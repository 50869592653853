import React, { cloneElement } from 'react'
import styles from './FormItem.module.scss'
import { useFormItem } from '../../hooks/useFormItem'

const FormItem = ({
  justUI = false,
  children,
  name,
  label,
  noLabel = false,
  labelCN = '',
  formItemCN = '',
  required = true,
  type,
  onChange,
  value
}) => {
  const isSelect = type === 'select'
  const { onChange: useFormItemOnChange, state } = useFormItem(name, isSelect && [])

  return (
    <div className={`${styles.item}${formItemCN ? ` ${formItemCN}` : ''}`}>
      {!noLabel && (
        <div className={`${styles.label} ${labelCN}`}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </div>
      )}
      {justUI
        ? children
        : cloneElement(children, {
            onChange: onChange || useFormItemOnChange,
            name,
            [isSelect ? 'values' : 'value']: value || state[name] || (isSelect ? [] : ''),
          })}
    </div>
  )
}

export default React.memo(FormItem)
