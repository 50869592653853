import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'
import logoImg from '../../images/logo.png'
import Notification from '../Notification'
import { FAVORITES_PAGE, HOME_PAGE, MESSENGER_PAGE } from '../../constants'
import { ApiContext } from '../../containers/ApiContainer'

import Modal from '../Modal'
import AuthForm from '../AuthForm'
import { useUserIsAdmin } from '../../hooks/useUserIsAdmin'
import {
  MenuItem,
  MyAdsPageNavLink,
  MyOrdersPageNavLink,
  NewAdvertPageNavLink,
  NewAdvertsModerationPageNavLink,
  RulePageNavLink,
} from '../Menu'

const Header = ({ className }) => {
  const {
    user,
    wishList,
    messages: { buyingCount, sellingCount },
  } = useContext(ApiContext)
  const isAdmin = useUserIsAdmin()
  const [isShowAuthModal, setIsShowAuthModal] = useState(false)

  const onCloseAuthModal = useCallback(() => setIsShowAuthModal(false), [])

  const notificationButtons = [
    {
      title: 'избранное',
      iconClass: 'heartIcon',
      count: wishList.count,
      href: FAVORITES_PAGE,
    },
    {
      title: 'Сообщения',
      iconClass: 'messageIcon',
      count: buyingCount + sellingCount,
      href: MESSENGER_PAGE,
    },
  ]

  return (
    <header className={`${className} ${styles.header}`}>
      {isShowAuthModal && (
        <Modal onClose={onCloseAuthModal}>
          <AuthForm onFinish={onCloseAuthModal} />
        </Modal>
      )}
      <div className={styles.headerContent}>
        <Link to={HOME_PAGE}>
          <img src={logoImg} className={styles.logo} alt="bookashka" title="bookashka" />
        </Link>
        <div className={styles.menu}>
          <RulePageNavLink />
          <MyAdsPageNavLink withIcon />
          <MyOrdersPageNavLink withIcon />
          {notificationButtons.map((el, index) => (
            <Notification key={index} {...el} />
          ))}
          <NewAdvertPageNavLink withIcon />
          {isAdmin && <NewAdvertsModerationPageNavLink />}
        </div>
        <div className={styles.actionMenu}>
          <MenuItem>
          <Link to={HOME_PAGE} className={styles.searchLink} >
            <span className={styles.search} /> Поиск
          </Link>
          </MenuItem>
          <a href="https://www.facebook.com/Bookashkashop" target="_blank" rel="noopener noreferrer" >
            <span className={styles.facebook} />
          </a>
          <a href="https://www.instagram.com/Bookashkashop" target="_blank" rel="noopener noreferrer" >
            <span className={styles.instagram} />
          </a>
          <MenuItem onClick={() => setIsShowAuthModal(true)}>
            {
              user
                ? <div className={styles.userName}>{user.firstname}</div>
                : <><span className={styles.login} /> Войти</>
            }
          </MenuItem>
        </div>
      </div>
    </header>
  )
}

export default Header
