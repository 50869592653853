import React, { useMemo } from 'react'
import styles from './Counter.module.scss'

const Counter = ({ count, yellow = false, green = false }) => {
  const color = useMemo(() => {
    if (yellow) return styles.yellow
    if (green) return styles.green
    return count === 0 ? styles.green : styles.yellow 
  }, [count, green, yellow])

  return <span className={`${styles.counter} ${color}`}>{count}</span>
}

export default Counter
