import React, { useCallback, useContext, useState } from 'react'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import Title from '../../components/Title'
import styles from './PwdResetRequestPage.module.scss'
import FormItem from '../../components/FormItem'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Form from '../../components/Form'
import { useForm } from '../../hooks/useForm'
import { formSchema } from './formSchema'
import { ApiContext } from '../../containers/ApiContainer'
import { NotificationManager } from 'react-notifications'

const PwdResetRequestPage = ({ error }) => {
  const { passwordResetRequest } = useContext(ApiContext)

  const form = useForm()

  const [emailDispatched, setDispatched] = useState(() => false)

  const prepareRequestBody = useCallback(() => {
    const { value, error } = formSchema.validate(form.state, {
      stripUnknown: true /*abortEarly: false*/,
    })
    if (error) {
      console.error('ValidationError:', error)
      alert(error)
      return
    }

    return value
  }, [form.state])

  const handleShowNotification = useCallback((msg, title) => {
    NotificationManager.success(msg, title)
  }, [])

  const handleSubmit = useCallback(() => {
    const preparedBody = prepareRequestBody()
    if (preparedBody) {
      const body = {
        user: preparedBody,
      }

      passwordResetRequest({
        body,
        cb() {
          setDispatched(true)
          handleShowNotification(`Ссылка для восстановления пароля успешно отправлена.`)
        },
      })
    }
  }, [prepareRequestBody, passwordResetRequest, handleShowNotification, setDispatched])

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="Восстановление Пароля" />
        <Title text="Восстановление Пароля" />
        <div className={styles.formSection}>
          <div>
            <p className={styles.text}>Добро пожаловать на сервис объявлений</p>
            <p className={styles.text}>
              Введите Вашу электронную почту и нажмите “Отправить ссылку” чтобы получить ссылку для
              восстановления пароля.
            </p>
            {emailDispatched ? (
              <>
                <p className={styles.textRed}>Мы Вас услышали!</p>
                <p className={styles.textGreen}>
                  Вам будет отправлено письмо с инструкциями по смене пароля. (если Ваш Email
                  зарегистрирован на нашем сайте)
                </p>
              </>
            ) : (
              <Form form={form}>
                <FormItem label="E-Mail" name="email" labelCN={styles.formItem}>
                  <Input type="email" placeholder="your@email.ru" />
                </FormItem>
                <div className={styles.submit}>
                  <Button onClick={handleSubmit}>Отправить ссылку</Button>
                  {error && <div className={styles.error}>{error}</div>}
                </div>
              </Form>
            )}
          </div>
        </div>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default PwdResetRequestPage
