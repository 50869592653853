import React, { useCallback } from 'react'
import styles from './Textarea.module.scss'

const Textarea = ({ onChange, ...props }) => {
  const handleChange = useCallback((e) => {
    onChange(e.target.value)
  }, [onChange])

  return (
    <textarea className={styles.textarea} rows={12} {...props} onChange={handleChange}/>
  )
}

export default Textarea
