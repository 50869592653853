import React, { useCallback } from 'react'
import styles from './Title.module.scss'
import { useHistory } from 'react-router-dom'
import { ADV_CARD_PAGE } from "../../constants";

const Title = ({ text, className = '', children, ...props }) => {
  const history = useHistory()
  const handleTitleClick = useCallback((e) => {
    const { uuid } = e.currentTarget.dataset
    return history.push(ADV_CARD_PAGE.replace(':uuid', uuid))
  }, [history])

  return (
    <div 
      className={`${styles.title} ${className}`} 
      onClick={handleTitleClick}
      {...props}
    >{text}{children}</div>
  )
}

export default Title
