import React from 'react'
import styles from './Distance.module.scss'

const Distance = ({ distance }) => {
  if (!distance) return null

  return (
    <div className={styles.distance}>{distance}km</div>
  )
}

export default Distance
