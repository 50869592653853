import React, { useContext, useEffect } from 'react'
import GridContainerBlock from '../../components/GridContainerBlock'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import Title from '../../components/Title'
import {
  RuleBlock1,
  RuleBlock2,
  RuleBlock3,
  RuleBlock4,
  RuleBlock5,
  RuleBlock6,
} from '../../components/RuleBlock'
import styles from './RulesPage.module.scss'
import { ApiContext } from '../../containers/ApiContainer'

const RulesPage = () => {
  const {
    user,
    getUserStats,
  } = useContext(ApiContext)

  useEffect(() => {
    if (user) getUserStats()
  }, [getUserStats, user])

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle title="Правила" />
        <Title text="Правила пользования" />
        <p className={styles.text}>
          Добро пожаловать на сервис объявлений, который позволит Вам найти и приобрести необходимый
          Вам товар.
          <br />
          <br />
          Данный сервис — это интернет-сайт, доступный через полную и мобильную версии и
          представляет собой электронный каталог объявлений о товарах, которые пользователи могут
          предлагать и искать с целью покупки/обмена.
        </p>
        <Title text="Инструкция использования" className={styles.title}/>
        <div className={styles.rules}>
          <RuleBlock1 />
          <RuleBlock2 />
          <RuleBlock3 />
          <RuleBlock4 />
          <RuleBlock5 />
          <RuleBlock6 />
        </div>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default RulesPage
