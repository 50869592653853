export const generatePageRange = (currentPage, lastPage, delta = 4) => {
  const range = []
  const startNumber = Math.max(2, currentPage - delta)
  const maxNumber = Math.min(lastPage - 1, currentPage + delta)
  for (let i = startNumber; i <= maxNumber; i += 1) {
    range.push(i)
  }

  range.unshift(1)

  return range
}
