import React, { useCallback, useMemo, useState } from 'react'

export const FormContext = React.createContext()

export const useForm = (initialValues = {}) => {
  const [state, setState] = useState(initialValues)

  const updateState = useCallback((obj) => {
    setState((s) => ({ ...s, ...obj }))
  }, [])

  return useMemo(
    () => ({
      state,
      setState: updateState,
      context: FormContext,
    }),
    [state, updateState]
  )
}
