import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainerBlock from '../../components/GridContainerBlock'
import Message from '../../components/Message'
import ContentBlock from '../../components/ContentBlock'
import PageBackTitle from '../../components/PageBackTitle'
import styles from './MessengerPage.module.scss'
import Counter from '../../components/Counter'
import avatarImg from '../../images/avatar/unknown.png'
import PoundIcon from '../../images/icons/PoundSvgIcon.svg'
import DeliveryIcon from '../../images/icons/DeliveryCarSvgIcon.svg'
import SendIcon from '../../images/icons/SendSvgIcon.svg'
import Title from '../../components/Title'
import { ApiContext } from '../../containers/ApiContainer'
import { HOME_PAGE } from '../../constants'
import { getFormattedDate } from '../../utils/dateFotmater'
import { useQueryParams } from '../../hooks/useQueryParams'
import SpinnerWrapper from '../../components/SpinnerWrapper'
import { showPleaseLogInError } from '../../utils/showPleaseLogInError'

export const SELL = 'sell'
export const BUY = 'buy'
const MessengerPage = () => {
  const [activeConversation, setActiveConversation] = useState(undefined)
  const [selectedConversation, setSelectedConversation] = useState(undefined)
  const msgRef = useRef()
  const history = useHistory()

  const {
    loadBuyingMessages,
    loadSellingMessages,
    loadMessages,
    messages: { buyingCount, sellingCount, buying, selling, listByUUID },
    user,
    isLoading,
    getUserStats,
    sendMessages,
    loadMessagesByProductIdAndBuyerId,
  } = useContext(ApiContext)
  const { productUuid, tab, buyerUuid } = useQueryParams()
  const [activeTab, setActiveTab] = useState(() => tab)

  useEffect(() => {
    if (user) {
      if (!buying.length) loadBuyingMessages()
      if (!selling.length) loadSellingMessages()
      getUserStats()
    } else {
      showPleaseLogInError()
      history.push(HOME_PAGE)
    }
  }, [
    user,
    history,
    loadBuyingMessages,
    loadSellingMessages,
    getUserStats,
    buying.length,
    selling.length,
  ])

  useEffect(() => {
    if (productUuid) {
      const id = tab === BUY ? user.uuid : buyerUuid
      const type = tab === BUY ? 'buying2' : 'selling2'
      loadMessagesByProductIdAndBuyerId({ aProductUuid: productUuid, buyerUuid: id, type })
    }
  }, [productUuid, buyerUuid]) // eslint-disable-line

  const activeConversationList = useMemo(() => (activeTab === SELL ? selling : buying), [
    activeTab,
    buying,
    selling,
  ])

  useEffect(() => {
    if (activeConversationList.length) {
      const activeConversation = productUuid
        ? activeConversationList.find((el) => el.product.uuid === productUuid)
        : activeConversationList[0]
      setActiveConversation(activeConversation?.uuid)
      const conversation = activeConversationList.find((el) => el.uuid === activeConversation?.uuid)
      setSelectedConversation(conversation)
      if (activeConversation?.uuid) loadMessages(activeConversation.uuid)
    }
  }, [productUuid, activeConversationList, loadMessages])

  const handleChangeActiveConversation = (e) => {
    const { id } = e.currentTarget
    setActiveConversation(id)
    const conversation = activeConversationList.find((el) => el.uuid === id)
    setSelectedConversation(conversation)
    loadMessages(id)
  }

  const handleSendMsg = useCallback(() => {
    if (msgRef.current.value.length) {
      sendMessages(selectedConversation?.uuid, {
        message: { senderUuid: user.uuid, senderFirstname: user.firstname, body: msgRef.current.value },
      })
      msgRef.current.value = ''
    }
  }, [selectedConversation, sendMessages, user])

  const handleTabClick = useCallback((e) => {
    const { tab } = e.currentTarget.dataset
    setActiveTab(tab)
    setSelectedConversation(undefined)
  }, [])

  const Tabs = () => (
    <div className={styles.tabs}>
      <div
        onClick={handleTabClick}
        data-tab={SELL}
        className={`${styles.tab} ${activeTab === SELL ? styles.activeTab : ''}`}
      >
        <span>продажа</span> <Counter count={buyingCount} />
      </div>
      <div
        onClick={handleTabClick}
        data-tab={BUY}
        className={`${styles.tab} ${activeTab === BUY ? styles.activeTab : ''}`}
      >
        <span>покупка</span> <Counter count={sellingCount} />
      </div>
    </div>
  )

  return (
    <GridContainerBlock>
      <ContentBlock noMargin>
        <PageBackTitle className={styles.pageTitle} title={<Tabs />} />
        <SpinnerWrapper isLoading={isLoading}>
          <div className={styles.container}>
            <div className={styles.left}>
              {activeConversationList.map(({ uuid, buyer, lastUpdated, product = {}, unreadMessagesCount = {} }) => (
                <div
                  key={uuid}
                  onClick={handleChangeActiveConversation}
                  id={uuid}
                  className={`${styles.conversation} ${
                    uuid === activeConversation ? styles.selected : ''
                  }`}
                >
                  <div>
                    <img
                      className={styles.advImage}
                      src={product?.mainImage?.url}
                      alt={product.name}
                      title={product.name}
                    />
                  </div>
                  <div className={styles.col2}>
                    <img className={styles.avatar} src={avatarImg} alt={''} />
                    <div className={styles.userInfoBlock}>
                      <div className={styles.grayColor}>
                        { product.seller.uuid === user.uuid ? (
                          `${buyer.firstname} ${buyer.lastname}`
                        ) : (
                          `${product.seller.firstname} ${product.seller.lastname}`
                        )}

                      </div>
                      <div className={styles.lightGrayColor}>({product.seller.address?.city})</div>
                    </div>
                  </div>
                  <div className={styles.col3}>
                    <div className={styles.lightGrayColor}>{getFormattedDate(lastUpdated)}</div>
                    <div className={styles.maT10}>
                      <Counter count={unreadMessagesCount} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedConversation && (
              <div className={styles.right}>
                <div className={styles.top}>
                  <Title text={selectedConversation.product?.name} data-uuid={selectedConversation.product?.uuid}/>
                  <div className={styles.productStatus}>
                    <div className={styles.productStatusItem}>
                      <DeliveryIcon />
                      <span>{selectedConversation.product?.delivery?.name}</span>
                    </div>
                    <div className={styles.productStatusItem}>
                      <PoundIcon />
                      <span>{selectedConversation.product?.status?.name}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.center}>
                  {listByUUID[activeConversation]?.map(
                    ({ uuid, sender, body, read, createdAt }) => {
                      const isMyMessage = sender.uuid === user.uuid // TODO: check this on real data
                      return (
                        <Message 
                          uuid={uuid} 
                          sender={sender} 
                          body={body} 
                          read={read} 
                          createdAt={createdAt} 
                          isMyMessage={isMyMessage} />
                      )
                    }
                  )}
                </div>
                <div className={styles.bottom}>
                  <textarea ref={msgRef} placeholder="Введите текст" className={styles.textArea} />
                  <SendIcon className={styles.saveBtn} onClick={handleSendMsg} />
                </div>
              </div>
            )}
          </div>
        </SpinnerWrapper>
      </ContentBlock>
    </GridContainerBlock>
  )
}

export default MessengerPage
