import axios from 'axios'
import { keysToCamelCase, keysToSnakeCase } from '../utils/keysToSpecificCase'
import { getAccessToken } from '../utils/session'

export const transformResponse = [(data) => keysToCamelCase(JSON.parse(data))]

const getAxios = (baseURL) => (dispatch, showSpinner = true) => {
  dispatch({ type: 'isLoading', payload: showSpinner })

  const instance = axios.create({
    baseURL,
    headers: { authorization: getAccessToken(), 'Content-Type': 'application/json' },
    transformResponse,
  })

  instance.interceptors.request.use(function (req) {
    let data = req.data
    const isFormData = req.data instanceof FormData

    if (data && !isFormData) {
      data = JSON.stringify(keysToSnakeCase(data))
    }
    return {
      ...req,
      data
    }
  })

  instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    dispatch({ type: 'isLoading', payload: false })

    const { data, ...rest } = response.data
    return {
      ...response,
      data,
      rest
    };
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    dispatch({ type: 'isLoading', payload: false })
    return Promise.reject(error.response || error);
  })

  return instance
}

const { REACT_APP_API_BASE_URL } = process.env

export const api = getAxios(`${REACT_APP_API_BASE_URL}`)
