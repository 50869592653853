import joi from 'joi'

// const selectReq = joi
//   .array()
//   .required()
//   .items({
//     id: joi.number().integer().required(),
//   })
//   .min(1)

const str = joi.string()

// const number = joi.number()

export const formSchema = joi.object({
  firstname: str.allow(null, ''),
  lastname: str.allow(null, ''),
  // image: "image_url",
  telephone: str.allow(null, ''),
  newsletter: joi.boolean(),
  addressFirstname: str.allow(null, ''),
  addressLastname: str.allow(null, ''),
  addressCompanyName: str.allow(null, ''),
  addressAddress1: str.allow(null, ''),
  addressAddress2: str.allow(null, ''),
  addressCity: str.allow(null, ''),
  addressPostcode: str.allow(null, ''),
  addressZone: joi.array(),
  addressCountry: joi.array(),
})
